import React from "react";
import "../styles/Email.css";

export const Email = ({ text }) => {
    return(
        <div className="email">
            <p classname="email-text">{text}</p>
        </div>
    )
}
