import React from "react";
import "../styles/Address.css";

export const Address = ({ text }) => {
    return(
        <div className="address">
            <p classname="address-text">{text}</p>
        </div>
    )
}
