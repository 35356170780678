import React, {Component} from 'react'
import {Description} from "./Description";
import {Launch} from "./Launch";
import Subscribe from "./Subscribe"
import {Copyright} from "./Copyright";
import {Address} from "./Address";
import {Email} from "./Email";
import {Logo} from "./Logo";

// import logo from "../images/test.svg";
import logo from "../images/convoshopper_logo.svg";
//import check from "../images/check-mark.svg"
import exclamation from "../images/exclamation.png"
import xmark from "../images/exclamation.png"

import '../styles/ComingSoon.css';

class ComingSoon extends Component {
  state = {
    logo:{
      alt: "Company Logo",
      src:logo,
    },
    description:{
      text:"Because we know how time consuming it is to navigate the wide variety of choices available online when shopping, we are building Convoshopper to enable efficient discovery of products that match your needs."
    },
    launch:{
      text:"COMING SOON!!!"
    },
    subscribe:{
      placeholder: "Email",
      buttonText: "Join Waitlist"
    },

    copyright:{
      text:"\xa9 2022, CONVOSHOPPER LTD"
    },

    address:{
      text:"20-22 Wenlock Road, London"
    },

    email:{
      text:"hello@convoshopper.co.uk"
    },

    notification:{
      src: "",
      alt: "",
      message: "",
      visible: false,
      level: ""
    }
  }

  configureNotification = level => {
    const notification = { ...this.state.notification };
    notification.level = level;
    if (level === "success") {
      //notification.src = check;
      notification.src = xmark;
      notification.alt = "Check Mark";
      notification.message = `Thank You`
    } else if (level === "warning") {
      notification.src = exclamation;
      notification.alt = "Exclamation Point";
      notification.message = `The email you entered is already on our mailing list.
                              Thank you for joining the community.`;
    } else {
      notification.src = xmark;
      notification.alt = "X Mark";
      notification.message = `There was an issue with your email submission.
                            Please check your email and try again.`;
    }
    this.setState({ notification });
  }

  showNotification = () => {
    const notification = { ...this.state.notification };
    notification.visible = true;
    this.setState({ notification }, () => {
      setTimeout(() => {
        notification.visible = false;
        this.setState({ notification });
      }, 3000);
    });
  };

  render(){
    const{
      logo,
      description,
      launch,
      notification,
      subscribe,
      copyright,
      address,
      email
    } = this.state;
  return (
    <html>
      <body>
    <div className="background">
      <Logo alt={logo.alt} src={logo.src}/>
      <Description text={description.text} />
      <Launch text={launch.text} src={notification.src} alt={notification.alt} message={notification.message} level={notification.level} visible={notification.visible} />
      <Subscribe placeholder={subscribe.placeholder} buttonText={subscribe.buttonText} configureNotification={this.configureNotification} showNotification={this.showNotification} />
    </div>
    </body>
    <footer className="footer">
    <Copyright text={copyright.text} />
    <Address text={address.text} />
    <Email text={email.text} />
    </footer>
    </html>
  )};
}

export default ComingSoon;
