import React from "react";
import "../styles/Copyright.css";

export const Copyright = ({ text }) => {
    return(
        <div className="copyright">
            <p classname="copyright-text">{text}</p>
        </div>
    )
}
