import React from "react";
import "../styles/Launch.css";

export const Launch = ({ text }) => {
    return(
        <div className="launch">
            <p classname="launch-text">{text}</p>
        </div>
    )
}

