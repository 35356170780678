import React from "react";
import "../styles/Description.css";

export const Description = ({ text }) => {
    return(
        <div className="description">
            <p classname="description-text">{text}</p>
        </div>
    )
}
